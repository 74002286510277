<template>
  <div>
    <div v-for="item in wastes" v-bind:key="item.ss01DictionaryId">
      <el-row>
        <p style="font-weight: bold;">{{ item.wasteName }}</p>
        <p>Код отхода: <span style="font-weight: bold;">{{ item.wasteCode }}</span></p>
        <el-col :span="16">
          <el-row style="font-weight: bold; margin: 5px;">
            <el-col :span="6">
              <span  style="font-weight: bold;">Дата</span>
            </el-col>
            <el-col :span="8">
              <span  style="font-weight: bold;">Объект</span>
            </el-col>
            <el-col :span="4">
              <span  style="font-weight: bold;">Количество</span>
            </el-col>
            <el-col :span="6">
              
            </el-col>
          </el-row>
          <el-row v-for="elem in item.orgs" v-bind:key="elem" :gutter="5">
            <el-row v-for="dt in elem.dats" v-bind:key="dt" :gutter="5" style="vertical-align: middle;">
              <el-row v-if="elem.wastorg[0].amount_of_current_unit>0">
                <el-col :span="6">
                  {{ dt.data.split('-')[2]+"."+dt.data.split('-')[1]+"."+dt.data.split('-')[0] }}
                </el-col>
                <el-col :span="8">
                  {{ elem.name }}
                </el-col>
                <el-col :span="4">
                  <el-input v-model="dt.out"></el-input>
                </el-col>
                <el-col v-if="dt.passport == null" :span="6">
                  <el-button type="primary" @click="openPasportModal(dt.id, dt.data)">Сопроводительный паспорт</el-button>
                </el-col>
                <el-col v-if="dt.passport != null" @click="openPasportModal(dt.id, dt.data)" :span="4">
                  <el-button type="success">Сопроводительный паспорт добавлен</el-button>
                </el-col>
              </el-row>
            </el-row>
          </el-row>
        </el-col>
      </el-row>

      <el-form :model="districtsList" ref="formedForm" label-position="top" label-width="300px">
        <el-row>
          <p>Передано для</p>
        </el-row>
        <el-row :gutter="15">
          <el-col :span="15">
            <el-select v-model="item.disposal" placeholder="Наименование">
              <el-option v-for="item in disposalNames" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row v-if="!('disposal' in item) && !valid">
          <div v-bind:style="{ color: '#FF0000', fontSize: '10px' }">Заполните поле</div>
        </el-row>
      </el-form>
      <div>
        <p>Выберите контрагента</p>
        <el-form prop="" :model="districtsList" ref="formedForm" label-position="top" label-width="300px"
          >
          <el-row :gutter="15">
            <el-col :span="15">
              <el-select filterable v-model="item.Contractors" placeholder="Наименование"
                :disabled="item.receivedFromPerson">
                <el-option v-for="item in listContractors.content" :key="item.contractorId"
                  :label="item.nameManual + '/' + (item.contractNumber == null ? '' : item.contractNumber)"
                  :value="item.contractorId">
                </el-option>
              </el-select>
            </el-col>
          </el-row>
          <el-row :gutter="15">
            <input type="checkbox" id="checkbox" v-model="item.receivedFromPerson" @change="editCa2(item)">
            <label for="">Реализация физическому лицу</label>
          </el-row>
          <el-row :gutter="15" v-if="item.receivedFromPerson && (new Date(item.data[0]!=undefined ? item.data[0] : item.data) > new Date(2024, 3, 26))">
            <el-col :span="15">
              ФИО:
              <el-input v-model="item.fio"></el-input>
            </el-col>
          </el-row>
          <el-row
            v-if="!('Contractors' in item) && !('receivedFromPerson' in item ? (item.receivedFromPerson && item.fio) : false) && !valid">
            <div v-bind:style="{ color: '#FF0000', fontSize: '10px' }">Заполните поле контрагента либо физ лица</div>
          </el-row>
        </el-form>
      </div>
      <div v-if="item.disposal == 6">
        <p>Объекты захоронения отходов</p>
        <el-form prop="" :model="districtsList" ref="formedForm" label-position="top" label-width="300px"
          >
          <el-row :gutter="15">
            <el-col :span="15">
              <el-select v-model="item.pss21" placeholder="Наименование">
                <el-option v-for="item in pss21list" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-col>
          </el-row>
          <el-row
            v-if="!('pss21' in item) && !('disposal' in item ? (item.disposal == 6 ? false : true) : (true)) && !valid">
            <div v-bind:style="{ color: '#FF0000', fontSize: '10px' }">заполните поле объекта захоронения отхода</div>
          </el-row>
        </el-form>
      </div>
    </div>

    <br>
    <el-button type="primary" v-if="false">Сопроводительный паспорт</el-button>
    <el-button type="primary" @click="submitForm">Сохранить и выгрузить</el-button>

    <custom-modal :visible="showModal === 'dataPasport'">
      <disposal-pasport-form :action="passport" :waste="wastes" :psId="psId"
        @addPasportToTable="addPasportToTable"></disposal-pasport-form>
    </custom-modal>

  </div>
</template>

<script>
import CustomModal from "@/components/customModal";
import { mapGetters } from "vuex";
import disposalPasportForm from "@/components/ecologist/disposal/disposalPasport";
import Cookies from "js-cookie";
import { AXIOS } from "@/AXIOS/http-common";
export default {
  name: "accompanyingPassport",
  components: { disposalPasportForm, CustomModal },

  data() {
    return {
      psId: 0,
      psIdtr:0,
      dats: [],
      passport: {
        number: null, contractor1: null, contractor2: { content: 0 }, contractor3: null, data: null,
        vehicle: null, full_name: null, tara_name: null, tara_vight: null, vehicle_vight: null, number_card: null,
        owner: null, ownerr: null,
        transfer: null, transferr: null,
        ca1: 'Ручной ввод', contractor1r: null,
        ca2: 'Контрагент', contractor2r: null,
        ca3: 'Контрагент', contractor3r: null,
        egr_full_name: null,
        chek_null_value: false,
        fileType: ".pdf",

        tr: 'Ответственное лицо', ow: 'Ответственное лицо',
        rules: {
          rul: [{ required: true, message: "Заполните поле", trigger: "blur" }],
        }
      },
      valid: true,
      user_id: null,
      out_all: 0,
      wastes: [
      ],
      districtsList: null,
      disposalNames: [
        {
          id: 1,
          name: "С целью использования"
        },
        {
          id: 2,
          name: "С целью обезвреживания"
        },
        {
          id: 3,
          name: "С целью захоронения по договору отчуждения"
        },
        {
          id: 4,
          name: "С целью хранения"
        },
        {
          id: 5,
          name: "Прочее"
        },
        {
          id: 6,
          name: "С целью захоронения без договора отчуждения"
        },
        {
          id: 7,
          name: "С целью заготовки"
        },
        {
          id: 8,
          name: "С целью сортировки"
        },
      ],
      pss21list: [
        {
          id: 211,
          name: "Полигон промышленных отходов"
        },
        {
          id: 212,
          name: "Полигон токсичных промышленных отходов"
        },
        {
          id: 221,
          name: "Полигон твердых коммунальных отходов "
        },
        {
          id: 290,
          name: "Другое"
        },
      ],
      search: {
        disposal: null,
        dateTo: null,
        waste: null,
        wasteCode: null,
        branch: null,
        subDivision: null,
        district: null,
      },
    };
  },

  computed: {
    ...mapGetters({
      showModal: "GETSHOWMODAL",
      listContractors: "GETLISTCONTRACTORS",
      NumberPassport: "GETDATASTORED",
    }),
  },

  methods: {

    editCa2(item) {
      console.log('1',item.receivedFromPerson);
      console.log('2',new Date(item.orgs[0].dats[0]!=undefined ? item.orgs[0].dats[0].data : item.orgs[0].dats.data) > new Date(2024, 3, 26));
      console.log('3',item.receivedFromPerson && (new Date(item.orgs[0].dats[0]!=undefined ? item.orgs[0].dats[0].data : item.orgs[0].dats.data) > new Date(2024, 3, 26)))
      console.log('dt1',new Date(item.data));
      console.log('dt2',new Date(2024, 3, 26));
      console.log(item.data);
      if(item.receivedFromPerson)
      {
        item.Contractors=null;
      }
      if (this.wastes[0].receivedFromPerson) {
        this.passport.contractor2r = 'Физическое лицо';
        this.passport.ca2 = 'Ручной ввод';
      }
      else {
        this.passport.ca2 = 'Контрагент';
      }
      
      console.log('2',item.receivedFromPerson);

    },
    MyClass(cfg) {
      return JSON.parse(JSON.stringify(cfg));
    },

    addPasportToTable(item, psId, wasts) {
      
      console.log("this.wastes", this.MyClass(this.wastes));
      this.psIdtr = psId;
      this.psId = psId;
      this.wastes.forEach(waste => {
        waste.orgs.forEach(org => {
          org.dats.forEach(dt => {
            if (dt.id == this.psId)
            {
              dt.passport = this.MyClass(item);
              wasts.forEach(wast => { 
                if(wast.wasteCode==waste.wasteCode)
                {
                  if (wast.tara_name == null)
                    dt.tara_name = null;
                  else
                    dt.tara_name = wast.tara_name+"";
                  if (wast.tara_vight == null)
                    dt.tara_vight = null;
                  else
                    dt.tara_vight = wast.tara_vight+"";
                }
              });
            }
              
          });
        });
      });
      this.passport = item;
      console.log("this.wastes", this.MyClass(this.wastes));
      //this.wastes[0]=item.wastes;
    },

    async openPasportModal(psid, data) {
      if (this.psIdtr!=psid)
    {
      console.log("this.psId", this.psId);
      console.log("this.passport", this.passport);
      console.log("this.wastes", this.wastes);
      this.passport.number = null;
      let year;
      this.wastes.forEach(waste => {
        year=new Date((typeof waste.data) == "object" ? waste.data[0] : waste.data);
      })
      await this.GetNumberPassport(year.getFullYear());
      //if (item.Contractors !== undefined)
      
      console.log("1");

      this.passport.contractor2 = this.wastes[0].Contractors;
      console.log("3");
      this.passport.contractor3 = this.wastes[0].Contractors;
      console.log("4");
      this.passport.data = data;

      console.log("2");
      this.wastes.forEach(waste => {
        waste.orgs.forEach(org => {
          org.dats.forEach(dt => {
            if (dt.id == this.psIdtr) {
              console.log("this.passport",this.passport);
              console.log("dt.passport",dt.passport);
              this.passport = this.MyClass(dt.passport);
              console.log("this.passport",this.passport);
              console.log("this.passport.number",this.passport.number);
              this.passport.number = this.passport.number + 1;
            }
          });
        });
      });
      console.log("3");

      console.log("2", this);
      this.psId = psid;
    }
      this.$store.dispatch("setShowModal", "dataPasport");
    },

    showData() {
      console.log(this.wastes);
    },

    async getAllContractors(data) {
      this.$store.dispatch("getAllContractors", data);
    },

    async submitForm() {

      
      if (this.validation()) {
        let numList=[]

        let listdt=[];
        for (const waste of this.wastes) {
          for (const org of waste.orgs) {
            for (const dt of org.dats) {
              let dat = [waste];
              
              
              dat[0].data = "" + (new Date(dt.data).getDate() < 10 ? ("0" + new Date(dt.data).getDate()) : new Date(dt.data).getDate()) + "." + (new Date(dt.data).getMonth() < 9 ? ("0" + (new Date(dt.data).getMonth() + 1)) : (new Date(dt.data).getMonth() + 1)) + "." + new Date(dt.data).getFullYear();
                  if (dt.passport == null)
                  dat[0].passport = {data:null};
                  else 
                  dat[0].passport = dt.passport;
                    dat[0].passport.data = "" + (new Date(dt.data).getDate() < 10 ? ("0" + new Date(dt.data).getDate()) : new Date(dt.data).getDate()) + "." + (new Date(dt.data).getMonth() < 9 ? ("0" + (new Date(dt.data).getMonth() + 1)) : (new Date(dt.data).getMonth() + 1)) + "." + new Date(dt.data).getFullYear();
                  dat[0].orgs = [org];
                  dat[0].out = dt.out + "";
                  dat[0].out_all=parseFloat(dat[0].out.replace(/[,]+/g, '.'));
                  dat[0].orgs[0].wastorg[0].out=dat[0].out_all;
                  dat[0].tara_name=dt.tara_name;
                  dat[0].tara_vight=dt.tara_vight;
              console.log(this.MyClass(dat));
              listdt.push(this.MyClass(dat[0]));
            }
          }
        }

        const response = await AXIOS.post("data-entry/receive/formwaste?userId="+this.user_id, { data: listdt });
        response.data.forEach(element1 => {
          if (element1 !== 0 && numList.find((element) => element.num == element1) == undefined)
          numList.push({fileType:".pdf",num:element1})
              });

              

        for (const numb of numList) {
                const passportResponse = await AXIOS.post("report/get/passport", numb, { responseType: "blob" });
                
                const url = window.URL.createObjectURL(new Blob([passportResponse.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", `Сопроводительный паспорт ${numb.fileType}`);
                document.body.appendChild(link);
                link.click();
        };
        

        this.$router.push({ path: "main" });
      }
      else {
        this.notification(
          "Ошибка валидации",
          "Заполните обязательные поля",
          "warning"
        );
      }

    },

    addPasport() {
      this.$store.dispatch("setShowModal", "disposalPasport");
    },

    async GetNumberPassport(year) {
      return await AXIOS.get(
        "report/get/NumberPassYear?year="+year
      ).then((response) => {
        if (this.passport.number == null) {
          this.passport.number = response.data.numb;
          this.passport.egr_full_name = response.data.egr_full_name;
          this.passport.contractor1r = response.data.egr_full_name;
          this.passport.contractor3r = response.data.egr_full_name;
        }
      });
    },
    validation() {
    
      this.valid = true;
      this.wastes.forEach((waste) => {
        console.log(waste);
        if (!('disposal' in waste)) {
          console.log(waste.disposal);
          this.valid = false;
         // return this.valid;
        }
        if (!('Contractors' in waste) && !('receivedFromPerson' in waste ? waste.receivedFromPerson  : false)) {
          console.log("'Contractors' in waste");
          console.log(waste.Contractors);
          console.log(waste.receivedFromPerson);
          this.valid = false;
         // return this.valid;
        }
        
        if (waste.receivedFromPerson==false)
        {
          if (waste.Contractors== null)
          this.valid = false;
        }
        if (waste.receivedFromPerson==true) {
          console.log("receivedFromPerson==true");
          console.log(waste.receivedFromPerson);

          if(waste.fio)
          {
            
          console.log(waste.fio);
          console.log(waste.data);
          console.log((typeof waste.data));
          console.log((typeof waste.data) == "object");
          console.log(waste.fio.length);
          console.log(new Date((typeof waste.data) == "object" ? waste.data[0] : waste.data) > new Date(2024, 3, 26));
            if(waste.fio.length<1 && (new Date((typeof waste.data) == "object" ? waste.data[0] : waste.data) > new Date(2024, 3, 26)))
              {this.valid = false;
             // return this.valid;
             }
          }
          else 
          {
            this.valid = false;
            //return this.valid;
          }
        }
        if (!('pss21' in waste) && !('disposal' in waste ? (waste.disposal == 6 ? false : true) : (true))) {
          this.valid = false;
         // return this.valid;
        }
       // this.valid = true;
       // return this.valid;
      })
      
      return this.valid;
    }
  },


  async mounted() {
    this.user_id = Cookies.get('userId');


    
    this.$route.query.wastes.forEach((waste) => {
      this.wastes.push(waste);
    })
    let i = 1;
    let s=[];
    this.wastes.forEach(async (waste, index_waste) => {


      var out_all = 0;
      this.$set(waste, 'tara_name', null);
      this.$set(waste, 'tara_vight', null);
      
      waste.orgs.forEach(async (org) => {
        if(org.dats == undefined)
          this.$set(org, 'dats', []);
        waste.data.forEach(element => {
          let obj = s.find(o => o.data == element)
          if(obj==undefined)
          {org.dats.push({ data: element, passport: null, out: org.wastorg[0].out, id: i });
          s.push({data: element,orgId:org.id,id:i})}
          else {
            org.dats.push({ data: element, passport: null, out: org.wastorg[0].out, id: obj.id });
          }
          i++;
        });


        if ('wastorg' in org)
          if (org.wastorg[0].out != "")
            out_all = out_all + parseFloat(org.wastorg[0].out.replace(/[,]+/g, '.'));
          else org.wastorg[0].out = 0;
      })
      this.$set(this.wastes[index_waste], 'out_all', Math.round(out_all * 100000) / 100000);
    })


    await this.getAllContractors({ page: 0, size: 1000 });
    console.log(this);
  },

};
</script>

<style scoped></style>
